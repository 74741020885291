import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { strings } from '../../utils/localizedStrings';
import LoginForm from './forms/login';
import toast from 'react-hot-toast';
import actionMessages from '../../utils/messages';
import useResize from './utils/useResize';

let fields = { mobile: true, orderId: true, email: false };

const ExhangeLayout = ({ exchangeProps }) => {
  const { meta_data, config, udfKeys } = exchangeProps.tracking.theme;
  const { lang } = exchangeProps.user;
  const [exchange, setExchange] = useState(exchangeProps);
  const uiState = useResize();

  useEffect(() => {
    if ('return_authorization_config' in config) {
      if (
        config.return_authorization_config.input_fields &&
        config.return_authorization_config.input_fields.indexOf('Email') > -1
      ) {
        fields.mobile = false;
        fields.email = true;
      }
    }
  }, [config]);

  const onSubmit = () => {
    const { orderId, mobile, email } = exchangeProps.loginForm;
    let query = {
      orderId: orderId.trim(),
      domain: exchangeProps.user.subdomain,
      isReturnExchange: true
    };
    if (mobile) {
      query.mobile = mobile.trim();
    }
    if (email) {
      query.email = email.trim();
    }
    exchangeProps.onSubmit(query).then(res => {
      if (res?.meta && res?.meta?.status === 200) {
        setExchange({ ...exchange, apiData: res.result });
      } else {
        toast.error(actionMessages.return.login(exchangeProps?.user.subdomain));
      }
    });
  };

  useEffect(() => {
    if (
      exchangeProps?.orderInfo?.isFetching === false &&
      exchange.apiData &&
      exchangeProps?.orderInfo?.loggedUser
    ) {
      if (
        exchangeProps?.orderInfo?.hasMultipleShipments &&
        exchangeProps?.orderInfo?.awbs?.length > 1 &&
        exchangeProps?.orderInfo?.shipmentIDs?.length > 1
      ) {
        const awbs = exchangeProps?.orderInfo.awbs.join(',');
        const shipmentIDs = exchangeProps?.orderInfo.shipmentIDs.join(',');
        exchangeProps.history.push(
          `/return-exchange?awbs=${awbs}&security_key=${shipmentIDs}`
        );
      } else {
        exchangeProps.history.push(
          `/return-exchange?security_key=${exchange.apiData.shipment_uuid}&waybill=${exchange.apiData.awb}`
        );
      }
    }
  }, [
    exchangeProps?.orderInfo?.isFetching,
    exchangeProps?.orderInfo?.loggedUser,
    exchange.apiData
  ]);

  const theIndusValley = ['theindusvalley'].includes(
    exchangeProps?.user.subdomain
  );

  return (
    <div
      className={`text-left main-container ${exchangeProps?.user.subdomain} ${
        exchangeProps?.page.appOnly ? 'webview' : ''
      }`}
      style={{ minHeight: uiState.conHeight }}
    >
      <div className="container-fluid">
        <div
          className={`row col-gutter-0 row-flex-over`}
          style={{ width: '100vw' }}
        >
          <div
            className={`col-xs-24 bg-return-home ${
              meta_data.return_background_image
                ? exchangeProps?.user.subdomain === 'thelabellife'
                  ? 'col-md-14 col-md-push-10'
                  : 'col-md-16 col-md-push-8'
                : 'hide'
            }`}
          >
            <div
              className="inline-image bg-brand-primary"
              style={{
                backgroundImage: `url(${
                  uiState.bodyWidth < 576 && udfKeys.mobileBanner
                    ? udfKeys.mobileBanner
                    : meta_data.return_background_image
                })`
                // minHeight: uiState.conHeight
              }}
            ></div>
          </div>
          <div
            className={`col-xs-24 return-box bg-brand-primary ${
              exchangeProps?.user.subdomain !== 'thelabellife' &&
              meta_data.return_background_image
                ? 'col-md-8 col-md-pull-16'
                : exchangeProps?.user.subdomain === 'thelabellife'
                ? 'col-md-10'
                : !meta_data.return_background_image
                ? 'col-md-8'
                : 'col-md-8 col-md-pull-16'
            }`}
            style={{
              minHeight:
                uiState.bodyWidth <= 768 && meta_data.return_background_image
                  ? uiState.conHeight - 150 // 150 is the image height in mobile ui
                  : uiState.conHeight
            }}
          >
            <h2>
              {udfKeys.returnExchangeHeading ??
                strings[lang].exchange(
                  ['82e', 'theindusvalley'].includes(
                    exchangeProps?.user.subdomain
                  )
                    ? 'Replacement'
                    : 'Exchange',
                  theIndusValley ? 'Refund' : 'Return'
                )}
            </h2>
            <p className="mrg-btm-20">
              {strings[lang].enterYourOrderInformation}
            </p>

            <LoginForm onSubmit={onSubmit} fields={fields} />

            <div className="mrg-top-30">
              {udfKeys.returnExchangeDisclaimer ? (
                <p className="text-center mrg-btm-10">
                  {udfKeys.returnExchangeDisclaimer}
                </p>
              ) : null}
              {meta_data.return_policy_url ? (
                <p className="text-center">
                  <a
                    href={meta_data.return_policy_url}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {strings[lang].ourReturnExchangePolicy(
                      theIndusValley ? 'Replacement' : 'Exchange',
                      theIndusValley ? 'Refund' : 'Returns'
                    )}
                  </a>
                </p>
              ) : null}
            </div>
            <div className="divider-horizontal bg-brand-secondary mrg-top-10 mrg-btm-10"></div>
            {meta_data.refund_policy_url || meta_data.term_condition_url ? (
              <div>
                <ul className="policy-links">
                  {meta_data.refund_policy_url ? (
                    <li>
                      <a
                        href={meta_data.refund_policy_url}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        {strings[lang].refundPolicy}
                      </a>
                    </li>
                  ) : null}
                  {meta_data.term_condition_url ? (
                    <li>
                      <a
                        href={meta_data.term_condition_url}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        {strings[lang].termsConditions}
                      </a>
                    </li>
                  ) : null}
                </ul>
              </div>
            ) : null}
            {udfKeys?.returnExchangeLoginNote ? (
              <>
                <div
                  className="-mrg-left-25 fs-1"
                  dangerouslySetInnerHTML={{
                    __html: udfKeys?.returnExchangeLoginNote
                  }}
                />
              </>
            ) : null}
          </div>
          {!meta_data.return_background_image && (
            <div
              className={`return-box hidden-xs hidden-sm bg-brand-primary ${
                exchangeProps?.user.subdomain === 'thelabellife'
                  ? 'col-md-14'
                  : 'col-md-16'
              }`}
              style={{ minHeight: uiState.conHeight }}
            />
          )}
        </div>
      </div>
    </div>
  );
};

ExhangeLayout.propTypes = {
  exchangeProps: PropTypes.object
};

export default ExhangeLayout;
