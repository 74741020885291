export const validateRequest = values => {
  const errors = {};
  if (!values.reason) {
    errors.reason = 'Required';
  }
  return errors;
};

export const validateLogin = values => {
  const errors = {};
  if (!values.orderId) {
    errors.orderId = 'Required';
  }
  if (!values.mobile) {
    errors.mobile = 'Required';
  }
  return errors;
};

export const validateAddress = values => {
  const errors = {};
  if (!values.name) {
    errors.name = 'Required';
  }
  if (!values.address) {
    errors.address = 'Required';
  }
  if (!values.pincode) {
    errors.pincode = 'Required';
  }
  if (!values.city) {
    errors.city = 'Required';
  }
  if (!values.state) {
    errors.state = 'Required';
  }
  if (!values.country) {
    errors.country = 'Required';
  }
  if (!values.phone) {
    errors.phone = 'Required';
  }
  return errors;
};
