import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router';
import { connect } from 'react-redux';
import { formValueSelector } from 'redux-form';
import { onReturnLogin } from '../../service';
import ExchangeLanding from './index';
import Wrapper from './wrapper';
import { isOnlySummaryScreenDomains } from './defaultValues';
import NotFoundExchange from './NotFoundExchange';

// there is a delay in getting redux data in functional components. So created this page to push latest props into the index page for exchange
class Exchange extends Component {
  render() {
    if (isOnlySummaryScreenDomains.includes(this.props.user.subdomain)) {
      return (
        <div
          className={`text-left main-container ${this.props.user.subdomain} ${
            this.props.page.appOnly ? 'webview' : ''
          } bg-brand-primary exchange`}
        >
          <div className="container-fluid">
            <div className="row-eq-height row return-container bg-brand-primary">
              <div className="col-md-8 ht-100 col-md-offset-8 col-xs-24">
                <NotFoundExchange />
              </div>
            </div>
          </div>
        </div>
      );
    }

    return <ExchangeLanding exchangeProps={this.props} />;
  }
}

Exchange.propTypes = {
  user: PropTypes.object,
  page: PropTypes.object,
  tracking: PropTypes.object,
  history: PropTypes.object,
  onSubmit: PropTypes.func,
  loginForm: PropTypes.object,
  orderInfo: PropTypes.object
};

Exchange.contextTypes = {
  router: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
  user: state.user,
  page: state.page,
  tracking: state.tracking,
  orderInfo: state.orderInfo,
  loginForm: formValueSelector('login')(state, 'orderId', 'mobile', 'email')
});

const mapDispatchToProps = dispatch => ({
  onSubmit: query => dispatch(onReturnLogin(query))
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(Wrapper(Exchange))
);
