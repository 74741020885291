/* eslint-disable jsx-a11y/label-has-for */
import React, { Fragment } from 'react';
import { reduxForm, Field } from 'redux-form';
import PropTypes from 'prop-types';
import { validateLogin } from './validate';
import { connect } from 'react-redux';
import { strings } from '../../../utils/localizedStrings';

let LoginForm = props => {
  const {
    handleSubmit,
    submitting,
    onSubmit,
    pristine,
    fields,
    user,
    tracking
  } = props;
  const classNameTextTitle = tracking.theme.udfKeys.titleTextClassName ?? '';
  const { lang } = user;
  const orderIdPlaceholder = tracking.theme.meta_data?.udf?.filter(
    item => item.key === 'orderIdPlaceholder'
  )?.[0]?.value;
  return (
    <Fragment>
      <form className="form" onSubmit={handleSubmit(onSubmit)}>
        <div className="inputGroup">
          <label className="text fs-12">
            <span className={` ${classNameTextTitle}`}>
              {strings[lang].orderId}
            </span>
            <span className="mandatory">
              <strong>*</strong>
            </span>
            <Field
              name="orderId"
              component="input"
              className={'ant-input'}
              required
              placeholder={
                orderIdPlaceholder ??
                `Enter Order ID${
                  user.subdomain === 'headsupfortails' ? ' starting with #' : ''
                }`
              }
            />
          </label>
          {user.subdomain === 'priyaasi' ? (
            <p className="mg-top-10">
              <b>Note: </b>orders id needs to be entered without #PR
            </p>
          ) : (
            ''
          )}
        </div>
        {fields.mobile ? (
          <div className="inputGroup">
            <label className="text fs-12">
              <span className={` ${classNameTextTitle}`}>
                {strings[lang].phoneNumber}
              </span>
              <span className="mandatory">
                <strong>*</strong>
              </span>
              <Field
                name="mobile"
                component="input"
                className={'ant-input'}
                pattern="^\d{10|11}$"
                required
                placeholder="e.g. 9876543210"
              />
            </label>
          </div>
        ) : null}
        {fields.email ? (
          <div className="inputGroup">
            <label className="text fs-12">
              <span className={` ${classNameTextTitle}`}>
                {strings[lang].emailId}
              </span>
              <span className="mandatory">
                <strong>*</strong>
              </span>
              <Field
                type="email"
                name="email"
                component="input"
                className={'ant-input'}
                required
                placeholder="Email"
              />
            </label>
          </div>
        ) : null}
        <div className="text-center mrg-top-20 mrg-btm-10">
          <button
            className={`ant-button full-width mrg-right-10 ${classNameTextTitle} ${
              pristine || submitting ? 'disable-element' : ''
            }`}
            type="submit"
            disabled={submitting}
          >
            {strings[lang].next}
          </button>
        </div>
      </form>
    </Fragment>
  );
};

LoginForm = reduxForm({
  form: 'login',
  validateLogin
})(LoginForm);

LoginForm.propTypes = {
  handleSubmit: PropTypes.func,
  onSubmit: PropTypes.func,
  submitting: PropTypes.bool,
  pristine: PropTypes.bool,
  fields: PropTypes.any,
  user: PropTypes.any,
  tracking: PropTypes.any
};

const mapStateToProps = state => ({
  user: state.user,
  tracking: state.tracking
});

export default connect(mapStateToProps)(LoginForm);
