import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router';
import { connect } from 'react-redux';
import toast from 'react-hot-toast';
import { formValueSelector } from 'redux-form';
import LoginForm from './forms/login';
import { onReturnLogin } from '../../service';
import actionMessages from '../../utils/messages';
import { strings } from '../../utils/localizedStrings';

class ReturnLanding extends Component {
  onSubmit = () => {
    const { user } = this.props;
    const { orderId, mobile, email } = this.props.loginForm;
    let query = { orderId: orderId.trim(), domain: this.props.user.subdomain };
    if (mobile) {
      query.mobile = mobile.trim();
    }
    if (email) {
      query.email = email.trim();
    }
    this.props.onSubmit(query).then(res => {
      if (
        res.meta &&
        res.meta.status === 200 &&
        this.props.orderInfo.loggedUser
      ) {
        if (
          this.props.orderInfo.hasMultipleShipments &&
          this.props.orderInfo.awbs?.length > 1 &&
          this.props.orderInfo.shipmentIDs?.length > 1
        ) {
          const awbs = this.props.orderInfo.awbs.join(',');
          const shipmentIDs = this.props.orderInfo.shipmentIDs.join(',');
          this.props.history.push(
            `/returns?awbs=${awbs}&security_key=${shipmentIDs}`
          );
        } else {
          this.props.history.push(
            `/returns?security_key=${res.result.shipment_uuid}&waybill=${res.result.awb}`
          );
        }
      } else {
        toast.error(actionMessages.return.login(user.subdomain));
      }
    });
  };

  render() {
    const { user, tracking, page } = this.props;
    const { meta_data, config, udfKeys } = tracking.theme;
    udfKeys.titleTextClassName = udfKeys.titleTextClassName ?? '';
    let fields = { orderId: true, mobile: true, email: false };
    const { lang } = user;
    if ('return_authorization_config' in config) {
      if (
        config.return_authorization_config.input_fields &&
        config.return_authorization_config.input_fields.indexOf('Email') > -1
      ) {
        fields.mobile = false;
        fields.email = true;
      }
    }

    return (
      <div
        className={`text-left main-container ${user.subdomain} ${
          page.appOnly ? 'webview' : ''
        }`}
      >
        <div className="container-fluid">
          <div
            className={`row col-gutter-0 row-flex-over`}
            style={{ width: '100vw' }}
          >
            <div
              className={`col-xs-24 bg-return-home ${
                meta_data.return_background_image
                  ? user.subdomain === 'thelabellife'
                    ? 'col-md-14 col-md-push-10'
                    : 'col-md-16 col-md-push-8'
                  : 'hide'
              }`}
            >
              <div
                className="inline-image"
                style={{
                  backgroundImage: `url(${meta_data.return_background_image})`
                }}
              ></div>
            </div>
            <div //@ToDo @anwin/Soham to remove this labellife condition and move to css
              className={`col-xs-24 return-box ${
                user.subdomain !== 'thelabellife' &&
                meta_data.return_background_image
                  ? 'col-md-8 col-md-pull-16'
                  : user.subdomain === 'thelabellife'
                  ? 'col-md-10'
                  : !meta_data.return_background_image
                  ? 'col-md-8'
                  : 'col-md-8 col-md-pull-16'
              }`}
            >
              <h2 className={`${udfKeys.titleTextClassName}`}>
                {strings[lang].returns}
              </h2>
              <p className="mrg-btm-20">
                {strings[lang].enterYourOrderInformation}
              </p>

              <LoginForm onSubmit={this.onSubmit} fields={fields} />

              <div className="mrg-top-30">
                {udfKeys.returnExchangeDisclaimer ? (
                  <p className="text-center mrg-btm-10">
                    {udfKeys.returnExchangeDisclaimer}
                  </p>
                ) : null}

                {meta_data.return_policy_url ? (
                  <p className="text-center">
                    <a
                      href={meta_data.return_policy_url}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {udfKeys?.returnExchangePolicyLabel
                        ? udfKeys.returnExchangePolicyLabel
                        : strings[lang].ourReturnExchangePolicy(
                            'Exchange',
                            'Returns'
                          )}
                    </a>
                  </p>
                ) : null}
              </div>
              <div className="divider-horizontal mrg-top-10 mrg-btm-10"></div>
              {meta_data.refund_policy_url ||
              meta_data.term_condition_url ||
              udfKeys?.cancellationPolicyURL ? (
                <div>
                  <ul className="policy-links">
                    {meta_data.refund_policy_url ? (
                      <li>
                        <a
                          href={meta_data.refund_policy_url}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          {strings[lang].refundPolicy}
                        </a>
                      </li>
                    ) : null}
                    {meta_data.term_condition_url ? (
                      <li>
                        <a
                          href={meta_data.term_condition_url}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          {strings[lang].termsConditions}
                        </a>
                      </li>
                    ) : null}

                    {udfKeys?.cancellationPolicyURL ? (
                      <li>
                        <a
                          href={udfKeys.cancellationPolicyURL}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          {udfKeys?.cancellationPolicyLabel ??
                            'Cancellation Policy'}
                        </a>
                      </li>
                    ) : null}
                  </ul>
                </div>
              ) : null}
              {udfKeys?.returnExchangeLoginNote ? (
                <>
                  <div
                    className="-mrg-left-25 fs-1"
                    dangerouslySetInnerHTML={{
                      __html: udfKeys.returnExchangeLoginNote
                    }}
                  />
                </>
              ) : null}
              {['trackmyorder'].includes(user.subdomain) && (
                <div className="policy-links">
                  {udfKeys.customPrivacyPolicy ? (
                    <p className="text-center">
                      <a
                        href={`${udfKeys.customPrivacyPolicy}`}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        Shop101 Privacy Policy
                      </a>
                    </p>
                  ) : null}
                  {udfKeys.clickpostPrivacyPolicy ? (
                    <p className="text-center">
                      <a
                        href={`${udfKeys.clickpostPrivacyPolicy}`}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        Clickpost Privacy Policy
                      </a>
                    </p>
                  ) : null}
                </div>
              )}
            </div>
            {!meta_data.return_background_image && (
              <div
                className={`return-box hidden-xs hidden-sm ${
                  user.subdomain === 'thelabellife' ? 'col-md-14' : 'col-md-16'
                }`}
              />
            )}
          </div>
        </div>
      </div>
    );
  }
}

ReturnLanding.propTypes = {
  user: PropTypes.object,
  page: PropTypes.object,
  tracking: PropTypes.object,
  history: PropTypes.object,
  onSubmit: PropTypes.func,
  loginForm: PropTypes.object,
  orderInfo: PropTypes.object
};

ReturnLanding.contextTypes = {
  router: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
  user: state.user,
  page: state.page,
  tracking: state.tracking,
  orderInfo: state.orderInfo,
  loginForm: formValueSelector('login')(state, 'orderId', 'mobile', 'email')
});

const mapDispatchToProps = dispatch => ({
  onSubmit: query => dispatch(onReturnLogin(query))
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(ReturnLanding)
);
